<template>
  <div>
    <v-container fluid class="py-8">
      <Notify ref="myNotify" :details="message"></Notify>
      <v-row>
        <v-col cols="12">
          <v-col cols="12">
            <v-card color="light">
              <v-app-bar color="light" class="border-left-primary">
                <v-toolbar-title>
                  {{ $t("sidebar.Inquiry Report") }}
                </v-toolbar-title>
              </v-app-bar>
              <FormFilter
                :filters="filters"
                @get_data="get_report"
                @reset="resetFilters"
              ></FormFilter>
              <Table
                @change_pre_page="ChangePrePage"
                @get_data_paginate="handlePageChange"
                @event_btn="event_btn"
                :btn_loading="btn_loading"
                :loading="loading"
                :headers="header"
                :items="inqueries"
                :paginate="paginate"
                :btn_table="btn_table"
                ref="table"
                @set_option="set_option"
              ></Table>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Table from "../Components/PaginationTable.vue";
import FormFilter from "../Components/Filter.vue";
import Notify from "../Components/Old/Notify.vue";
import InquiryService from "../../services/inquiry.service";
export default {
  name: "Ticket-Page",
  components: {
    Table,
    Notify,
    FormFilter,
  },
  data() {
    return {
      options: {},
      btn_table: {
        excel: true,
        print: true,
      },
      btn_loading: {
        excel: false,
        print: false,
      },
      loading: true,
      filters: [],
      message: {
        msg: null,
        type: null,
      },

      header: [
        {
          text: this.$i18n.t("inquiry.Inquery Text en"),
          value: "inquery",
          align: "center",
        },
        {
          text: this.$i18n.t("inquiry.Inquery Text ar"),
          value: "inquery_ar",
          align: "center",
        },
        {
          text: this.$i18n.t("inquiry.answer"),
          value: "answer",
          align: "center",
        },
        {
          text: this.$i18n.t("sidebar.Inquiry Group"),
          value: "groupName",
          align: "center",
        },
        {
          text: this.$i18n.t("order.customer"),
          value: "customer",
          align: "center",
        },
      ],
      inqueries: [],
      paginate: {
        page: 1,
        itemsPerPage: 0,
        search: "",
        total: 0,
      },
    };
  },

  methods: {
    set_option(val) {
      this.options = val;
    },
    get_report(reset = false, type = null) {
      this.loading = true;
      const formData = new FormData();
      if (!reset) {
        console.log(this.filters);
        this.filters.filter(function (filter) {
          formData.append(filter.name, filter.value);
        });
      }
      if (type == "pre_page") {
        formData.append("paginate", this.paginate.itemsPerPage);
      }
      return InquiryService.inqieries_report(this.paginate.page, formData).then(
        (response) => {
          // console.log(response);
          this.inqueries = response.data.inqueries.data;
          this.paginate.total = response.data.inqueries.total;
          this.paginate.itemsPerPage = parseInt(
            response.data.inqueries.per_page
          );
          this.loading = false;
          if (reset) {
            this.filters = response.data.filters;
            this.options = {};
            this.$refs.table.resetOption();
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
    handlePageChange(page) {
      console.log(page);
      this.paginate.page = page;
      this.get_report();
    },
    ChangePrePage(page) {
      this.paginate.itemsPerPage = parseInt(page);
      this.paginate.page = 1;
      this.get_report(false, "pre_page");
    },
    resetFilters() {
      this.paginate.page = 1;
      this.get_report(true);
    },
    event_btn(name) {
      if (name == "excel") {
        this.export_excel();
      } else if (name == "print") {
        this.print_table();
      }
    },
    print_table() {
      document.querySelector("table").classList.add("print");
      window.print();
    },
    export_excel() {
      this.btn_loading.excel = true;
      const formData = new FormData();
      this.filters.filter(function (filter) {
        formData.append(filter.name, filter.value);
      });
      this.$http({
        url: this.$baseURL + "api/inquires/inquiryReportExport",
        responseType: "blob",
        method: "post",
        data: formData,
      }).then(
        (response) => {
          this.btn_loading.excel = false;
          // console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "inqueries_reports_" + new Date().toLocaleString() + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          this.btn_loading.excel = false;
          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
    },
  },
  mounted() {
    this.get_report(true);
    document.title = this.$i18n.t("sidebar.Inquiry Report");
  },
};
</script>
